.rating-comment-list {
  .comment-container {
    margin: 32px -16px;
    padding: 0 0 24px 24px;
    width: 100%;
  }

    .comment-container:not(:last-child) {
      border-bottom: 1px solid #A4A4A4;
    }
  .comment-container {

    .comment-replies {
      border-left: 4px solid #EEEEEE;
    }
  }

  .zero-state {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50vh;
  }
}