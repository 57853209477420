.component-page {
  min-height: 80vh;
}

@media only screen and (max-width: 420px) {
  .component-page {
    padding: 0;
  }

    .component-page.talent-page {
      padding-bottom: 86px;
    }
}
