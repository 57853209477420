@use 'src/framework/utils/variables';

$sizes: none, xxs, xs, s, m, l, xl, xxl;
$directions: top, left, bottom, right;
$orientations: vertical, horizontal;

$margin-sizes: (
  none: 0,
  xxs: variables.$margin-xxs,
  xs: variables.$margin-xs,
  s: variables.$margin-s,
  m: variables.$margin-m,
  l: variables.$margin-l,
  xl: variables.$margin-xl,
  xxl: variables.$margin-xxl
);

$padding-sizes: (
  none: 0,
  xxs: variables.$margin-xxs,
  xs: variables.$margin-xs,
  s: variables.$margin-s,
  m: variables.$margin-m,
  l: variables.$margin-l,
  xl: variables.$margin-xl,
  xxl: variables.$margin-xxl
);

.af-box.--flex {
    display: flex;
  }

.af-box {

  @each $size in $sizes {
    &.--m-#{$size} {
      margin: map-get($margin-sizes, $size) !important;
    }
  
    &.--p-#{$size} {
      padding: map-get($padding-sizes, $size) !important;
    }
  
    @each $direction in $directions {
      &.--m-#{$direction}-#{$size} {
        margin-#{$direction}: map-get($margin-sizes, $size) !important;
      }
  
      &.--p-#{$direction}-#{$size} {
        padding-#{$direction}: map-get($padding-sizes, $size) !important;
      }
    }
  
    @each $orientation in $orientations {
      &.--m-#{$orientation}-#{$size} {
        @if $orientation == vertical {
          margin-top: map-get($margin-sizes, $size) !important;
          margin-bottom: map-get($margin-sizes, $size) !important;
        }
  
        @if $orientation == horizontal {
          margin-left: map-get($margin-sizes, $size) !important;
          margin-right: map-get($margin-sizes, $size) !important;
        }
      }
  
      &.--p-#{$orientation}-#{$size} {
        @if $orientation == vertical {
          padding-top: map-get($padding-sizes, $size) !important;
          padding-bottom: map-get($padding-sizes, $size) !important;
        }
  
        @if $orientation == horizontal {
          padding-left: map-get($padding-sizes, $size) !important;
          padding-right: map-get($padding-sizes, $size) !important;
        }
      }
    }
  }
}
