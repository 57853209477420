$border-radius: 4px;
$card-section-divider: 1px solid rgba(0,0,0,0.1);

.skeleton-applicant-card {
  box-shadow: 0 2px 6px rgba(0,0,0,0.16), 0 1px 2px rgba(0,0,0,0.23);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  border-radius: $border-radius;
}

.skeleton-applicant-card:not(:first-child) {
    margin-top: 32px;
  }

.skeleton-applicant-card:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }

.skeleton-applicant-card {

  .ant-typography {
    display: block;
  }

  .header {
    padding: 16px;
    background: linear-gradient(135deg, #ddd 0%, #999 100%);
    color: #fff;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;

    .heading {
      flex: 1 1 0;

      .title {
        font-size: 1.1rem;
      }
  
      .subtitle {
        font-size: 0.75rem;
      }
    }

    .status {
      flex: 0 1 0;
      align-items: center;
      justify-content: center;

      span {
        min-width: 88px;
        padding: 8px;
        text-align: center;
        font-size: 0.65rem;
        border-radius: 4px;
      }
    }
  }

  .profile {
    .photo {
      flex: 1 1 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
      }
    }

    .info {
      flex: 2 1 0;

      .gallery {
        flex-wrap: wrap;
        border-bottom: 1px solid rgba(0,0,0,0.1);
        padding: 0 8px;

        .image-container {
          position: relative;
          padding: 0.5rem;
          flex: 1 1 2rem;
    
          img {
            display: block;
            border-radius: 4px;
            width: 100%;
            height: 3rem;
            object-fit: cover;
            object-position: 50% 20%;
          }
    
          .show-more {
            position: absolute;
            top: 0;
            left: 0;
            color: #fff;
            font-size: 1rem;
            background-color: rgba(0,0,0,0.7);
            width: calc(100% -  1rem);
            height: calc(100% -  1rem);
            border-radius: 4px;
            margin: 0.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      .experiences {
        padding: 8px 16px;

        .experience:not(:first-child) {
          margin-top: 4px;
        }
      }
    }
  }

  .action {
    border-top: $card-section-divider;

    div {
      padding: 16px;
      text-align: center;
    }

      div:first-child {
        border-bottom-left-radius: $border-radius;
      }

      div:last-child {
        border-bottom-right-radius: $border-radius;
      }
  }
}