.component-steps.af-box {
  width: 100%;
                                                                                                                                                                                                                                                                                   
  .progress-container {
    padding: 16px 8px;
    margin-right: 8px;
  }

  .text-container {
    margin-top: 24px;
    
    h4.ant-typography {
      margin-bottom: 0;
    }
  }
}