.div-reply-comment-button {
  .btn-reply-comment {
    display: flex;
    align-items: center;
    color: #574DD3;
    font-weight: 600;
    padding: 0;
  
    span {
      margin-left: 0.5rem;
    }
  }

  .reply-textbox-title {
    font-weight: 600;
  }

  .reply-textbox-desc {
    color: #444444;
    font-size: small;
  }

  .btn-submit {
    margin-top: 1rem;
  }
}