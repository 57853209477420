.component-menu {
  padding: 1rem;
  position: fixed;
  top: 0;
  z-index: 100;
  max-width: inherit;
  width: 100%;
  align-items: center;
  transition: 100ms cubic-bezier(0.215, 0.61, 0.355, 1);
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);

  .logo {
    width: 45px;
    transition: 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  .name {
    font-size: 1rem;
    font-family: Quicksand;
    letter-spacing: 3px;
    padding-left: 1rem;
  }

  .burger-menu {
    padding: 0.5rem;
    z-index: 100;

    button {
      background: transparent;
      border: none;
      cursor: pointer;
    }

    .notification-icon{
      font-size: x-large;
    }
  }

  .profile-menu {
    button {
      background: transparent;
      border: none;
      cursor: pointer;
      box-sizing: content-box;
      height: 2.3rem;
      width: 2.3rem;
    }

    img {
      border-radius: 50%;
      height: 100%;
      width: 100%;
      object-fit: cover;
      border: 1px solid rgba(0, 0, 0, 0.3);
    }
  }
}

.component-menu.--shrank {
    background-color: #fff;
    padding: 0.5rem;
    box-shadow: 0px -10px 28px 6px rgba(0, 0, 0, 0.3);

    .burger-menu > button > span {
      transition: all 0.3s ease-in-out;
      color: black;
    }

    .logo {
      width: 30px;
    }

    .name {
      font-size: 1rem;
    }
  }
