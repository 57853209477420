.multiple-datepicker {
    .date-input {
        color: black !important;
    }

    .calendar-picker {
        padding: 0;
        height: 0;
        position: absolute;
        left: 0;
        bottom: 0;
    }
}