.component-stepper-input {
  align-items: center;

  * {
    flex-grow: 0;
  }

  .text-black {
    color: black;
  }

  .input-number input {
    text-align: center;
  }

  .btn-minus {
    .component-button {
      padding: inherit 0;
    }
  }

  .btn-plus {
    .component-button {
      padding: inherit 0;
    }
  }
}