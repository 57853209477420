.component-video-upload-manager {
  .gallery-preview {
    flex-wrap: wrap;
    max-width: 100%;

    label.upload-media-button {
      cursor: pointer;
      flex: 1 1 7rem;
      color: #1890ff;
      border: 2px dashed #1890ff;
      border-radius: 4px;
      height: 6rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      padding: 1rem;
      text-align: center;
    }

    .image-container {
      position: relative;
      flex: 1 1 7rem;
      padding: 0.5rem;
      width: 8rem;

      img {
        display: block;
        border-radius: 4px;
        width: 100%;
        height: 6rem;
        object-fit: cover;
        object-position: 50% 20%;
        cursor: pointer;
      }

      .image-placeholder {
        display: block;
        width: 100%;
        height: 6rem;
      }

      .processing-placeholder {
        color: #fff;
        font-size: 0.75rem;
        background-color: rgba(0,0,0,0.5);
        display: block;
        width: 100%;
        height: 6rem;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      .close {
        position: absolute;
        top: 5px;
        right: 4px;
        background: none;
        border: none;
        font-size: 1.25rem;
        padding: 4px;
        line-height: 15px;
        cursor: pointer;

        .anticon-close-circle {
          border-radius: 50%;
          background: #fff;
        }
      }
    }
  }
}