@use '../utils/variables';

.af-flex-child {
  position: relative;
  flex: 1 1 0;
}

.af-flex-child.--no-grow {
    flex: 0 1 auto;
    width: auto;
  }
