.component-button {
  color: #574dd3;
  font-weight: 600;
  font-size: 1rem;
  background-color: #fff;
  border: 1px solid #574dd3;
  border-radius: 3px;
  padding: 8px 16px;
  cursor: pointer;
}
.component-button:disabled {
    cursor: not-allowed;
    color: #A4A4A4;
    border-color: #A4A4A4;
  }
.component-button:visited {
    color: #574dd3;
  }
.component-button.--primary {
    color: #fff;
    background: linear-gradient(135deg, #647dee 0%, #7f53ac 100%);
    font-weight: 400;
  }
.component-button.--primary:visited {
      color: #fff;
    }
.component-button.--block {
    width: 100%;
  }
.component-button.--rounded {
    border-radius: 25px;
  }
.component-button.--solid {
    background-color: #574dd3;
    color: white;
  }
.component-button.--solid:disabled {
      background-color: #A4A4A4;
      border: 0;
    }
.component-button.--ghost {
    border: 0;
  }