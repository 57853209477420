
  .component-onboarding-banner.--hide {
    height: 0px;
  }
  
.component-onboarding-banner {
  
  .banner-wrapper {
    color: rgba(0, 0, 0, 0.88);
    width: 100%;
    background-color: #e6f4ff;
    border: 1px solid #91caff;
    border-radius: 4px;
    justify-content: space-evenly;
    align-items: center;
    opacity: 1;
  }
  
    .banner-wrapper.--hide {
      transition: 0.5s ease;
      opacity: 0;
      transform: translateY(-50px);
    }
  
  .banner-wrapper {
  
    .divider {
      border-color: #91caff;
      height: 1.5rem;
    }
  }
}
