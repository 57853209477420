.skeleton-directory-card {
  box-shadow: 0px 4px 15px 2px rgba(0, 0, 0, 0.23);
  width: 100%;

  .icon {
    margin-right: 0.5rem;
  }

  .project-details {
    position: relative;

    .show-more-link-button {
      position: absolute;
      right: 16px;
    }
  }

  .project-title {
    display: block;
    padding: 1rem;
    background: linear-gradient(135deg, #647dee 0%, #7f53ac 100%);
    color: #fff;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .project-roles {
    .role-title {
      background: #d9d9d9;
      justify-content: space-between;

      span.ant-typography {
        display: flex;
        align-items: center;
      }
    }
  }
}
