.flyout-button {
  padding: 1rem 1.5rem;
  background-color: transparent;
  border: none;
  text-align: left;
  font-weight: 600;
  cursor: pointer;
  display: block;
}

.component-flyout-item {
  @extend .flyout-button;
  color: #000;
  background-color: #fff;
}

.component-flyout-item:hover {
    color: #000;
    background-color: #e5e5e5;
    transition: background-color 300ms linear;
  }

.component-flyout-item:focus {
    outline: 2px solid;
  }

.component-flyout {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 300;

  .overlay {
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0;
    transition: 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }

  .panel {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    box-shadow: 0px -10px 28px 6px rgba(0, 0, 0, 0.3);
    transform: translateX(400px);
    width: 300px;
    height: 100%;
    transition: 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);

    .panel-header {
      // padding: 1rem 1.5rem;
      display: flex;
      justify-content: flex-end;

      button {
        @extend .flyout-button;
        // padding: 1rem;
      }
    }
  }
}

.component-flyout.--open {
    .overlay {
      opacity: 0.5;
    }

    .panel {
      transform: translateX(0);
    }
  }
