@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&family=Karla:wght@300;500&family=Knewave&family=Nanum+Gothic&family=Spectral&family=Trade+Winds&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@300&family=Nunito:wght@500&family=Quicksand:wght@400&family=Smooch&display=swap');
@import "~react-image-gallery/styles/css/image-gallery.css";
@import "~react-loading-skeleton/dist/skeleton.css";

body {
  margin: 0;
  font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* #root {
  display: flex;
  justify-content: center;
} */

@keyframes bg{
  0%{
    background-position-x: 0;
  }
  
  100%{
    background-position-x: 10000px;
  }
}