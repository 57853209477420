.skeleton-project-card {
  box-shadow: 0 2px 6px rgba(0,0,0,0.16), 0 1px 2px rgba(0,0,0,0.23);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.skeleton-project-card:not(:first-child) {
    margin-top: 32px;
  }

.skeleton-project-card:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }

.skeleton-project-card {

  .ant-typography {
    display: block;
  }

  .header {
    padding: 16px 0;
    text-align: center;
    background: linear-gradient(135deg, #ddd 0%, #999 100%);

    .title {
      font-size: 1.4rem;
      color: #fff;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  
    .subtitle {
      color: #fff;
      text-transform: capitalize;
    }
  }

  .summary {
    border-top: 1px solid rgba(0,0,0,0.1);

    .attribute {
      text-align: center;
      padding: 4px;
  
      .label {
        font-size: 0.75rem;
      }
  
      .value {
        font-size: 1.3rem;
      }
    }
  }

  .description {
    padding: 16px;
    border-top: 1px solid rgba(0,0,0,0.1);
    border-bottom: 1px solid rgba(0,0,0,0.1);
    text-align: center;
  }

  .details {
    border-bottom: 1px solid rgba(0,0,0,0.1);

    .attribute {
      align-items: center;
      padding: 4px 0;

      .label {
        flex: 0 1 136px;
        text-align: right;
        padding-left: 16px;
        padding-right: 8px;
        font-size: 0.75rem;
        line-height: 0.75rem;
      }

        .label.date {
          align-self: flex-start;
          margin-top: 10px;
        }

      .value {
        flex: 1 1 auto;
        text-align: left;
        padding-right: 16px;
        padding-left: 8px;
        font-size: 1.2rem;

        small {
          display: inline-block;
          font-size: 0.75rem;
          vertical-align: middle;
          width: 48px;
          margin-right: 8px;
          text-align: right;
        }
      }
    }
  }

  .action {
    div {
      padding: 16px;
      text-align: center;
    }
  }
}