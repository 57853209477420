.div-rating-comment {
  justify-content: start;
  align-items: center;
}

.div-rating-comment.--highlight {
    background-color: #FFFFBB;
  }

.div-rating-comment {

  .mb-0 {
    margin-bottom: 0;
  }

  .author-container {
    margin-bottom: 1rem;

    .author-profile-pic {
      width: 3.5rem;
      height: 3.5rem;
      object-fit: cover;
      border-radius: 50px;
      margin-right: 1rem;
    }

    .author-name {
      font-weight: 600;
      font-size: medium;
    }

    .comment-date {
      font-size: small;
      color: #A4A4A4;
    }

    .action-dropdown {
      margin-left: auto;
      text-align: right;
      padding-right: 1rem;

      .dropdown-btn {
        font-size: large;
        color: #A4A4A4;
      }
    }
  }

  .comment {
    color: black;
    font-size: medium;
  }

  .edit-comment-form {
    .action-buttons-wrapper {
      justify-content: end;
      padding-top: 0.5rem;

      .btn-save-edit {
        margin-left: 0.5rem;
        padding: 0 2rem;
      }
    }
  }
}

.rating-comment-dropdown-overlay {
  li.ant-dropdown-menu-item {
    padding: 0.45rem 2rem 0.45rem 1rem;
  }
}