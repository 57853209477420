$border-radius: 4px;
$card-section-divider: 1px solid rgba(0,0,0,0.1);

.skeleton-role-card {
  box-shadow: 0 2px 6px rgba(0,0,0,0.16), 0 1px 2px rgba(0,0,0,0.23);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  border-radius: $border-radius;
}

.skeleton-role-card:not(:first-child) {
    margin-top: 32px;
  }

.skeleton-role-card:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }

.skeleton-role-card {

  .ant-typography {
    display: block;
  }

  .role-header {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    padding: 16px;
    background: linear-gradient(135deg, #ddd 0%, #999 100%);

    .title {
      font-size: 1.4rem;
      color: #fff;
      text-overflow: ellipsis;
      overflow: hidden;
      display: inline;
    }
  
    .subtitle {
      display: inline;
      margin-left: 16px;
      font-size: 0.75rem;
      color: #fff;
      text-transform: uppercase;
    }
  }

  .role-summary {
    border-top: $card-section-divider;
    border-bottom: $card-section-divider;

    .attribute {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 0 1 112px;
      text-align: center;
      padding: 4px;
      
      .label {
        font-size: 0.75rem;
      }
  
      .value {
        font-size: 1.3rem;
      }
    }
    
    .description {
      flex: 1 1 0;
      padding: 16px;
      margin: 0;
      text-align: center;
      // border-left: $card-section-divider;
    }
  }

  .role-details {
    border-bottom: $card-section-divider;

    .attribute {
      align-items: center;
      padding: 4px 0;

      .label {
        flex: 0 1 144px;
        text-align: right;
        padding-right: 8px;
        font-size: 0.75rem;
        line-height: 0.75rem;
      }

      .value {
        flex: 1 1 auto;
        text-align: left;
        padding-left: 8px;
        font-size: 1.2rem;

        small {
          font-size: 0.75rem;
          vertical-align: middle;
        }
      }
    }
  }

  .role-action {
    div {
      padding: 16px;
      text-align: center;
    }

      div:first-child {
        border-bottom-left-radius: $border-radius;
      }

      div:last-child {
        border-bottom-right-radius: $border-radius;
      }
  }
}