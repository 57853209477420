.talent-withdraw-application-modal {
    h4 {
      padding: 16px 0;
    }
  
    .bold {
      font-weight: 600;
    }
  
    .text-danger {
      color: red;
    }
  
    .reason-list {
      margin-bottom: 0.5rem;
    }
  
    .btn-confirm-withdraw {
      margin: 1rem 0;
      white-space: normal;
      height: auto;
    }
  }